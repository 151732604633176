import breadcrumbComp from "../../common/breadcrumb-comp";
import AddFavourite from "../../utility/js/addFav.js";
import EncryptUtility from "../../utility/js/encrypt-utility.js";
import materialMovementService from "../js/material-movement-service";
import commonAPIService from "../../common/js/common-api-service";
import Utility from "../../../shared/utility";
export default {
  data() {
    return {
      actionList: [],
      lpnStatus: true,
      lpnText: "",
      mainPage: "",
      favouriteList: [],
      pageUrl: "",
      subPage: "",
      showAdd: false,
      subPageUrl: "",
      backSlash: true,
      backSlashParent: true,
      refresh: true,
      userId: EncryptUtility.localStorageDecrypt("userID"),
      entireLPNDisabled: false,
      showSelectLPNDisabled: false,
      ceValue: 0,
      showEntireLPNInTransit: false,
      disabledTakeOwnership: true,
      lpnNumber: "",
      lpnNum: "",
      showSelectLPNInTransit: false,
      targetInTransitLPN: "",
      partESNBCNSerialNo: "",
      sourceBinLPN: "",
      sourceLPN: "",
      lpnQuantity: "",
      disableTargetLPN: false,
      disablePartBCNESN: true,
      disableBinLPN: true,
      disableLPN: true,
      disableQuantity: true,
      showTransferToLPN: false,
      availableQty: 0,
      disableLPNNum: false,
      lpnDetailsDialog: false,
      lpnDetailsItems: [],
      showViewReset: false,
      lpnData: [],
      showEntireLPNDetails: false,
      showSelectLPNDetails: false,
      disabledSearchLPN: false,
      disabledTargetSearchLPN: false,
      showUnitID: false,
      showOtherFields: false,
      allowIntransit: false,
      showConfirm: false,
      isPick4Ship: false,
      lpnMsgUser: "",
      lpnDetailsHeaders: [
        { text: "Part Number", align: "start", value: "PartNumber" },
        { text: "Warehouse", value: "Warehouse" },
        { text: "Quantity", value: "Quantity" },
      ],
      lpnRule: [
        (v) => (v || "").length <= 39 || "Max Length of 39 character",
        (v) => !!v || "Field is required",
        (value) => /^[0-9]{1,3}(?:-[0-9]{3})*$/.test(value) || "Enter a correct format: 000-000-000-000",
      ],
      max50Rule: [(v) => !!v || "Field is required", (v) => (v || "").length <= 50 || "Value must be 50 characters or less"],
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.breadcrumbLPNData();
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  mounted() {
    this.onPageLoadGetButtons();
  },
  methods: {
    //Reset Function
    resetFunction() {
      this.entireLPNDisabled = false;
      this.showSelectLPNDisabled = false;
      this.disabledTakeOwnership = true;
      this.lpnNumber = "";
      this.showEntireLPNInTransit = false;
      this.showSelectLPNInTransit = false;
      this.targetInTransitLPN = "";
      this.partESNBCNSerialNo = "";
      this.sourceBinLPN = "";
      this.sourceLPN = "";
      this.lpnQuantity = "";
      this.disableTargetLPN = false;
      this.disablePartBCNESN = true;
      this.disableBinLPN = true;
      this.disableLPN = true;
      this.disableQuantity = true;
      this.showTransferToLPN = false;
      this.availableQty = 0;
      this.disableLPNNum = false;
      this.lpnDetailsDialog = false;
      this.lpnDetailsItems = [];
      this.showViewReset = false;
      this.showEntireLPNDetails = false;
      this.showSelectLPNDetails = false;
      this.lpnData = [];
      this.disabledSearchLPN = false;
      this.disabledTargetSearchLPN = false;
      this.showUnitID = false;
      this.showOtherFields = false;
      this.allowIntransit = false;
      this.showConfirm = false;
      this.onPageLoadGetButtons();
      if (this.showEntireLPNInTransit) {
        this.$refs.EntireLPNForm.resetValidation();
      }
      if (this.showSelectLPNInTransit) {
        this.$refs.SelectLPNForm.resetValidation();
      }
    },
    //Get Buttons on page load
    async onPageLoadGetButtons() {
      let pageData = await materialMovementService.onPageLoadGetButtons("get", this.userId, this.ceValue);
      if (pageData.message !== "NA") {
        this.entireLPNDisabled = pageData[0]?.enable_entire == 1 ? false : true;
        this.showSelectLPNDisabled = pageData[0]?.enable_select == 1 ? false : true;
        this.breadcrumbLPNData();
        if (this.isPick4Ship) {
          this.entireLPNDisabled = true;
          this.showSelectLPNDisabled = true;
          let Alert = {
            type: "error",
            isShow: true,
            message: this.lpnMsgUser,
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        }
      }
    },
    //Show LPN Data on breadcrumb
    async breadcrumbLPNData() {
      let lpnStatus = await AddFavourite.getLPNDetails(this.userId);
      this.lpnText = lpnStatus[0]?.lpn_info;
      this.lpnNum = lpnStatus[0]?.lpn;
      this.allowIntransit = lpnStatus[0]?.intransit;
      this.isPick4Ship = lpnStatus[0]?.ispickforship;
      this.lpnMsgUser = lpnStatus[0]?.additionalmsg;
      if (this.lpnNum !== "" && this.lpnNum !== undefined) {
        this.moveSelUnitsToIntransit();
      } else {
        this.moveEntireUnitToIntransit();
      }
    },
    //Take Ownership
    async takeOwnership() {
      if (this.$refs.EntireLPNForm.validate()) {
        let obj = {
          lpn: this.lpnNumber,
          user_id: parseInt(this.userId),
          method: 1,
          ce: parseInt(this.ceValue),
          web: 1,
        };
        let showMsg = true;
        let lpnTransferData = await materialMovementService.wholeLPNTransfer("post", obj, showMsg);
        if (lpnTransferData.message !== "NA") {
          this.entireLPNDisabled = lpnTransferData[0]?.enable_entire == 1 ? false : true;
          this.showSelectLPNDisabled = lpnTransferData[0]?.enable_select == 1 ? false : true;
          this.showEntireLPNInTransit = false;
          this.disableLPNNum = true;
          this.showViewReset = false;
          this.onPageLoadGetButtons();
        }
      }
    },
    //Move entire unit click event
    moveEntireUnitToIntransit() {
      this.showEntireLPNInTransit = true;
      this.showSelectLPNInTransit = false;
    },
    //Move select units button click event
    moveSelUnitsToIntransit() {
      this.showSelectLPNInTransit = true;
      this.showEntireLPNInTransit = false;
      if (this.lpnNum !== null && this.lpnNum !== "") {
        this.targetInTransitLPN = this.lpnNum;
        this.disableTargetLPN = true;
        this.disabledTargetSearchLPN = true;
        this.disablePartBCNESN = false;
        this.showViewReset = true;
        this.searchLPN();
      } else {
        this.targetInTransitLPN = "";
        this.disableTargetLPN = false;
        this.disabledTargetSearchLPN = false;
      }
    },
    //Get next available LPN number
    async getLPNNumber() {
      let lpnData = await commonAPIService.getNextAvailableLPN("get", this.userId);
      if (lpnData.message !== "NA") {
        this.targetInTransitLPN = lpnData[0]?.lpn;
        this.targetLPNCheck();
      }
    },
    //Validation of Unit ID
    async validatePartBCNESN() {
      if (this.partESNBCNSerialNo !== "" && this.partESNBCNSerialNo !== undefined) {
        this.ceValue = 0;
        let obj = {
          it_lpn: this.targetInTransitLPN,
          user_id: parseInt(this.userId),
          pass: 1,
          bcn: this.partESNBCNSerialNo,
          ce: this.ceValue,
        };
        let showMsg = false;
        let partBCNESNValid = await materialMovementService.validationCheckLPNBCN("post", obj, showMsg);
        if (partBCNESNValid !== null && partBCNESNValid !== undefined) {
          if (partBCNESNValid.message !== "NA") {
            this.disablePartBCNESN = true;
            this.disableBinLPN = !partBCNESNValid[0]?.enable_bin;
            this.disableLPN = !partBCNESNValid[0]?.enable_lpn;
            this.disableQuantity = !partBCNESNValid[0]?.enable_qty;
            this.showTransferToLPN = partBCNESNValid[0]?.enable_submit;
            if (this.showTransferToLPN) {
              this.lpnQuantity = partBCNESNValid[0]?.qty;
              this.transferLPN();
              this.showOtherFields = false;
            } else {
              this.showOtherFields = true;
            }
          }
        }
      }
    },
    //Validation of Bin/LPN
    async validateBinLPN() {
      if (this.sourceBinLPN !== "" && this.sourceBinLPN !== undefined) {
        this.ceValue = 0;
        let obj = {
          it_lpn: this.targetInTransitLPN,
          user_key: parseInt(this.userId),
          pass: 2,
          bcn: this.partESNBCNSerialNo,
          bin: this.sourceBinLPN,
          ce: this.ceValue,
        };
        let showMsg = false;
        let lpnBinValid = await materialMovementService.validationCheckLPNBCN("post", obj, showMsg);
        if (lpnBinValid.message !== "NA") {
          this.disableBinLPN = !lpnBinValid[0]?.enable_bin;
          this.disableLPN = !lpnBinValid[0]?.enable_lpn;
          this.disableQuantity = !lpnBinValid[0]?.enable_qty;
          this.showTransferToLPN = lpnBinValid[0]?.enable_submit;
        }
      }
    },
    //Validation of LPN
    async validateLPN() {
      if (this.$refs.sourceLPNForm.validate()) {
        this.ceValue = 0;
        let obj = {
          it_lpn: this.targetInTransitLPN,
          user_key: parseInt(this.userId),
          pass: 3,
          bcn: this.partESNBCNSerialNo,
          bin: this.sourceBinLPN,
          lpn: this.sourceLPN,
          ce: this.ceValue,
        };
        let showMsg = false;
        let lpnValid = await materialMovementService.validationCheckLPNBCN("post", obj, showMsg);
        if (lpnValid.message !== "NA") {
          this.disableBinLPN = !lpnValid[0]?.enable_bin;
          this.disableLPN = !lpnValid[0]?.enable_lpn;
          this.disableQuantity = !lpnValid[0]?.enable_qty;
          this.showTransferToLPN = lpnValid[0]?.enable_submit;
          this.availableQty = lpnValid[0]?.avail_qty;
        }
      }
    },
    //Validation of Quantity
    async validateQuantity() {
      if (this.lpnQuantity !== "" && this.lpnQuantity !== undefined) {
        this.ceValue = 0;
        let obj = {
          it_lpn: this.targetInTransitLPN,
          user_key: parseInt(this.userId),
          pass: 4,
          bcn: this.partESNBCNSerialNo,
          bin: this.sourceBinLPN,
          lpn: this.sourceLPN,
          qty: parseInt(this.lpnQuantity),
          ce: this.ceValue,
        };
        let showMsg = false;
        let quantityValid = await materialMovementService.validationCheckLPNBCN("post", obj, showMsg);
        if (quantityValid.message !== "NA") {
          this.disableBinLPN = !quantityValid[0]?.enable_bin;
          this.disableLPN = !quantityValid[0]?.enable_lpn;
          this.disableQuantity = !quantityValid[0]?.enable_qty;
          this.showTransferToLPN = quantityValid[0]?.enable_submit;
        }
      }
    },
    //Transfer To LPN button click event
    async transferLPN() {
      if (this.$refs.SelectLPNForm.validate()) {
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        let obj = {
          user_id: parseInt(this.userId),
          to_lpn: this.targetInTransitLPN,
          bcn: this.partESNBCNSerialNo,
          from_bin: this.sourceBinLPN,
          from_lpn: this.sourceLPN,
          qty: this.lpnQuantity,
          ce: this.ceValue,
        };
        this.axios
          .post("mm/move_lpn_sort_transfer", obj)
          .then((response) => {
            if (response.status == 200 || response.status == 204) {
              this.breadcrumbLPNData();
              if (this.lpnNum !== "" && this.lpnNum !== undefined) {
                let moveSelUnitsInTransit = true;
                this.formsReset(moveSelUnitsInTransit);
              } else {
                this.formsReset();
              }
              this.onPageLoadGetButtons();
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "success",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.errorMessage = error.message;
          });
      }
    },
    //Get LPN content
    async getLPNContents() {
      let obj = {
        lpn: this.showEntireLPNInTransit ? this.lpnNumber : this.targetInTransitLPN,
        user_id: parseInt(this.userId),
        transferred: 0,
      };
      let showMessage = false;
      let lpnContents = await materialMovementService.getLPNContents("post", obj, showMessage);
      if (lpnContents !== "NA" && lpnContents.message !== "NA") {
        this.lpnDetailsItems = lpnContents;
        this.lpnDetailsDialog = true;
      } else {
        this.lpnDetailsItems = [];
        this.lpnDetailsDialog = false;
        let Alert = {
          type: "error",
          isShow: true,
          message: "No data available for this LPN",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    //Close Dialog
    closeDialog() {
      if (this.lpnDetailsDialog) {
        this.lpnDetailsDialog = false;
        this.lpnDetailsItems = [];
      }
    },
    //Reset forms button
    formsReset(moveSelUnitsInTransit) {
      if (moveSelUnitsInTransit) {
        this.showSelectLPNInTransit = true;
        this.partESNBCNSerialNo = "";
        this.sourceBinLPN = "";
        this.sourceLPN = "";
        this.lpnQuantity = 0;
        this.availableQty = 0;
        this.disableTargetLPN = false;
        this.disablePartBCNESN = true;
        this.disableBinLPN = true;
        this.disableLPN = true;
        this.disableQuantity = true;
        this.showTransferToLPN = true;
        this.showViewReset = false;
        this.showSelectLPNDetails = false;
        this.lpnData = [];
        this.showConfirm = false;
        this.showUnitID = false;
        this.showOtherFields = false;
        this.targetLPNCheck();
      } else {
        this.targetInTransitLPN = "";
        this.partESNBCNSerialNo = "";
        this.sourceBinLPN = "";
        this.sourceLPN = "";
        this.lpnQuantity = 0;
        this.availableQty = 0;
        this.disableTargetLPN = false;
        this.disablePartBCNESN = true;
        this.disableBinLPN = true;
        this.disableLPN = true;
        this.disableQuantity = true;
        this.showTransferToLPN = true;
        this.showViewReset = false;
        this.showSelectLPNDetails = false;
        this.lpnData = [];
        this.showConfirm = false;
        this.showUnitID = false;
        this.showOtherFields = false;
        this.showSelectLPNInTransit = false;
      }
      this.lpnNumber = "";
      this.showViewReset = false;
      this.disableLPNNum = false;
      this.showEntireLPNDetails = false;
      this.lpnData = [];
      this.disabledSearchLPN = false;
      this.disabledTakeOwnership = true;
      this.showEntireLPNInTransit = false;
      if (this.showEntireLPNInTransit) {
        this.$refs.EntireLPNForm.resetValidation();
      }
      if (this.showSelectLPNInTransit) {
        this.$refs.SelectLPNForm.resetValidation();
      }
    },
    //to check if its a number with hyphen of not
    lpnNumFormat(event, num, loc) {
      if (loc == "LPN") this.lpnNumber = Utility.formatLPNNumber(event, num);
      else if (loc == "SourceLPN") this.sourceLPN = Utility.formatLPNNumber(event, num);
      else {
        this.targetInTransitLPN = Utility.formatLPNNumber(event, num);
      }
    },
    //LPN validation on entering
    async lpnCheck() {
      if (this.$refs.LPNForm.validate()) {
        this.ceValue = 0;
        let obj = {
          lpn: this.lpnNumber,
          user_id: parseInt(this.userId),
          method: 1,
          ce: this.ceValue,
        };
        let showMsg = false;
        let lpnValid = await materialMovementService.moveLPNtoIntransit("post", obj, showMsg);
        if (lpnValid.message !== "NA") {
          this.searchLPN();
          this.showViewReset = true;
        }
      }
    },
    //Target In Transit LPN check
    async targetLPNCheck() {
      if (this.$refs.targetLPNForm.validate()) {
        this.ceValue = 0;
        let obj = {
          lpn: this.targetInTransitLPN,
          user_id: parseInt(this.userId),
          method: 2,
          ce: this.ceValue,
        };
        let showMsg = false;
        let lpnValid = await materialMovementService.moveLPNtoIntransit("post", obj, showMsg);
        if (lpnValid[0]?.Result == "Success") {
          this.disableTargetLPN = true;
          this.searchLPN();
          this.showViewReset = true;
          this.showConfirm = true;
        }
      }
    },
    //Confirm Click event
    confirmClick() {
      this.showUnitID = true;
      this.showConfirm = false;
      this.disablePartBCNESN = false;
    },
    //Create Label based on number of Labels entered
    searchLPN() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let searchLPN = {
        user_id: parseInt(this.userId),
        lpn: this.showEntireLPNInTransit ? this.lpnNumber : this.targetInTransitLPN,
      };
      this.axios
        .post("/mm/lpn_details", searchLPN)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let responsedata = JSON.parse(response.data.body.message);
            if (responsedata?.Result !== undefined && responsedata?.Result !== null) {
              this.lpnData = [];
              this.lpnData = responsedata?.Result;
              if (this.showEntireLPNInTransit) {
                this.showEntireLPNDetails = true;
                this.disabledSearchLPN = true;
              }
              if (this.showSelectLPNInTransit) {
                this.showSelectLPNDetails = true;
                this.disabledTargetSearchLPN = true;
                this.showViewReset = true;
                if (this.lpnNum !== null && this.lpnNum !== "") {
                  this.showUnitID = true;
                }
              }
              this.lpnSearched = true;
              this.disableLPNNum = true;
              this.disabledTakeOwnership = false;
            } else {
              this.lpnData = [];
              if (this.showEntireLPNInTransit) {
                this.showEntireLPNDetails = false;
                this.disabledSearchLPN = false;
              }
              if (this.showSelectLPNInTransit) {
                this.showSelectLPNDetails = false;
                this.disabledTargetSearchLPN = false;
                this.showViewReset = false;
                this.showUnitID = false;
              }
              this.lpnSearched = false;
              this.disableLPNNum = false;
              this.disabledTakeOwnership = true;
              let Alert = {
                type: "error",
                isShow: true,
                message: "No Data for the LPN",
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          this.showViewReset = false;
          this.lpnData = [];
          if (this.showEntireLPNInTransit) {
            this.showEntireLPNDetails = false;
            this.disabledSearchLPN = false;
          }
          if (this.showSelectLPNInTransit) {
            this.showSelectLPNDetails = false;
            this.disabledTargetSearchLPN = false;
            this.showUnitID = false;
          }
          this.lpnSearched = false;
          this.disableLPNNum = false;
          this.disabledTakeOwnership = true;
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //to check if its a number of not
    isNumber(event) {
      let value = Utility.isNumber(event);
      return value;
    },
    //To brings page based actions to the page
    actionSetter(action) {
      if (this.actionList !== undefined && this.actionList !== null && this.actionList !== "") {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },
    //Add or remove Favourite
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
  },
  components: {
    breadcrumbComp,
  },
};
